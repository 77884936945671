import LockIcon from "@assets/icons/lock.svg";
import { getUserEmail } from "@components/Login/selectors";
import MoneyInput from "@legacyComponents/Form/input/MoneyInput/MoneyInput.component";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import { updateZakatAPI } from "@Zakat/store/actions/zakat";
import { navigate } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "../../store";
import { zakatTopupSelector } from "@Zakat/store/selectors/zakat";
import {
  HeaderTextContainer,
  InputContainer,
  OptionsContainer,
} from "../summary/styles/topup.style";
import { Header, StyledBoldText } from "../summary/styles/summary.style";
import Tooltip from "@components/Tooltip";
import { CurrencyContext } from "@components/CurrencyContext";
import useAnalytics from "@helpers/useAnalytics";
import PageWrapper from "@Zakat/components/PageLayout/PageWrapper";

const ZakatTopup: React.FC = () => {
  const dispatch = useDispatch<typeof store.dispatch>();
  const userEmail = useSelector(getUserEmail);
  const topUp = useSelector(zakatTopupSelector);
  const [topup, setTopup] = useState<number | undefined>(topUp);
  const { currencySymbol } = useContext(CurrencyContext);
  const firePageViewAnalytics = useAnalytics("pageView");

  useEffect(() => {
    const firePageView = async (eventName: string) => {
      (await firePageViewAnalytics)({
        eventName,
      });
    };
    firePageView("zakat:topup_pageViewed");
  }, []);

  useEffect(() => {
    setTopup(topUp);
  }, [topUp]);

  const handleConfirm = async () => {
    await dispatch(
      updateZakatAPI({
        data: {
          topUp: topup,
        },
        email: userEmail,
      })
    ).unwrap();

    navigate("/app/gift-aid");
  };

  const handleDontTopUp = async () => {
    if (topUp) {
      // If the user has previously added any value, we should remove it if they change their mind
      await dispatch(
        updateZakatAPI({
          data: {
            topUp: 0,
          },
          email: userEmail,
        })
      ).unwrap();
    }

    navigate("/app/gift-aid");
  };

  return (
    <PageWrapper>
      <HeaderTextContainer>
        <Header>Zakat Top-up</Header>
        <p>
          If you would like to top-up your Zakat (adding some sadaqah
          essentially), please enter the amount below.
        </p>
        <Tooltip
          label="Important Information"
          text="Please note this section should not be used to cover Zakat-ul-Fitr, Fidyah/Kaffarah or to purify your wealth. We recommend to deal with these areas outside the IFG Zakat Calculator."
        />
      </HeaderTextContainer>

      <OptionsContainer>
        <div>
          <StyledBoldText>Yes, top-up my Zakat</StyledBoldText>
        </div>
        <div>
          <button onClick={handleDontTopUp}>
            <StyledBoldText>{"No, Don't top-up my Zakat"}</StyledBoldText>
          </button>
        </div>
      </OptionsContainer>
      <InputContainer>
        <MoneyInput
          heading="Zakat Top-up Amount"
          value={topup?.toString()}
          currency={currencySymbol}
          onChange={(value) => setTopup(Number.parseFloat(value))}
        />
      </InputContainer>
      <FormButton
        text="Confirm Top-up"
        leftArrowLabel="Back"
        disclaimerIcon={<LockIcon />}
        disclaimerText={
          <div>
            We take your{" "}
            <a
              href="https://www.islamicfinanceguru.com/security-policy"
              target="_blank"
              rel="noreferrer"
            >
              privacy seriously
            </a>
            . Our institutional-grade security ensures your data is
            confidential.
          </div>
        }
        onClick={handleConfirm}
        leftArrowOnClick={() => {
          navigate("/app/charity-allocation");
        }}
      />
    </PageWrapper>
  );
};

export default ZakatTopup;
