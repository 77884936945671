import styled from "styled-components";
import { Text } from "../../../../components/Text/Text.component";

export const CharityValueContainer = styled.div`
  display: flex;
  gap: 1em;
  @media (max-width: 760px) {
    margin-left: 1em;
  }
`;

export const CharityItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1em;

  @media (max-width: 760px) {
    flex-direction: column;
  }
`;

export const AllocationCharityContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1em;
`;

export const SmallText = styled(Text)`
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CharityName = styled(Text)`
  text-align: left;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HeadingText = styled(Text)`
  text-align: left;
`;

export const Header = styled.h1`
  text-align: left;
  width: 100%;
  font-weight: bold;
  margin: 2rem 0 0 1.8rem;
  font-size: 1.5rem;
  text-transform: capitalize;
`;

export const HeaderTextContainer = styled.div`
  width: fit-content;
  margin: 0 auto;
  & > div {
    width: fit-content;
    margin-top: 0.5em;
  }
`;
