import FormButton from "@legacyComponents/FormButton/FormButton.component";
import LockIcon from "@assets/icons/lock.svg";
import store from "@Zakat/store";
import Tooltip from "@components/Tooltip";
import { navigate } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header, StyledBoldText } from "../summary/styles/summary.style";
import { OptionsContainer } from "../summary/styles/topup.style";
import {
  HeaderTextContainer,
  StyledForm,
  ZakatGiftAidContainer,
  ZakatGiftAidContent,
} from "./giftaid.style";
import { getUserEmail } from "@components/Login/selectors";
import {
  zakatGiftAidSelector,
  zakatTopupSelector,
} from "@Zakat/store/selectors/zakat";
import DropDown, {
  DropDownValue,
} from "@legacyComponents/DropDown/DropDown.component";
import Input from "@legacyComponents/Form/input/Input/Input.component";
import { ZakatGiftAid } from "@Zakat/store/reducers/reducers";
import { initialData, titleOptions, titles, ZakatGiftAidSchema } from "./data";
import { Formik } from "formik";
import {
  createGiftAidAPI,
  updateGiftAidAPI,
} from "@Zakat/store/actions/createGiftAid";
import { getPortfolioData } from "@components/PortfolioData/selectors";
import { zakatCalculator } from "@Zakat/lib/zakat";
import {
  DEFAULT_GOLD_PRICE,
  DEFAULT_SILVER_PRICE,
} from "@Zakat/lib/zakat/consts";
import { ButtonContainer } from "./giftaid.style";
import { CurrencyContext } from "@components/CurrencyContext";
import { totalAllocatedSelector } from "@Zakat/store/selectors/totalAllocatedSelector";
import useAnalytics from "@helpers/useAnalytics";
import PageWrapper from "@Zakat/components/PageLayout/PageWrapper";

const ZakatGiftAidPage: React.FC = () => {
  const giftAid = useSelector(zakatGiftAidSelector);
  const email = useSelector(getUserEmail);
  const totalAllocatedToCharities = useSelector(totalAllocatedSelector);
  const { formatCurrency } = useContext(CurrencyContext);
  const dispatch = useDispatch<typeof store.dispatch>();
  const firePageViewAnalytics = useAnalytics("pageView");

  useEffect(() => {
    const firePageView = async (eventName: string) => {
      (await firePageViewAnalytics)({
        eventName,
      });
    };
    firePageView("zakat:giftAid_pageViewed");
  }, []);

  const [title, setTitle] = useState<DropDownValue | undefined>({
    value: "",
    label: "",
  });

  const handleNext = (values: ZakatGiftAid) => {
    if (giftAid) {
      dispatch(
        updateGiftAidAPI({
          email,
          data: {
            ...values,
            title: title?.label || "",
          },
        })
      ).unwrap();
    } else {
      dispatch(
        createGiftAidAPI({
          email,
          data: {
            ...values,
            title: title?.label || "",
          },
        })
      ).unwrap();
    }

    navigate("/app/payment");
  };

  useEffect(() => {
    if (giftAid?.title) {
      setTitle({
        value: giftAid?.title,
        label: titles[giftAid?.title],
      });
    }
  }, [giftAid?.title]);

  const portfolio = useSelector(getPortfolioData);
  const calculateZakat = zakatCalculator(
    DEFAULT_GOLD_PRICE,
    DEFAULT_SILVER_PRICE
  );
  const topup = useSelector(zakatTopupSelector);
  const { zakat } = calculateZakat(portfolio);

  // zakat amount + top up x %charity allocation x 25% = giftAid amount
  const getGiftAidPrice = () =>
    (zakat + (topup || 0)) * (totalAllocatedToCharities / 100) * 0.25;

  return (
    <PageWrapper>
      <HeaderTextContainer>
        <Header>Gift Aid</Header>
        <p>
          Give more without costing you a penny. Add Gift Aid and the UK
          government will top up your donation by 25%.
        </p>
        <p>
          By choosing Gift Aid, you confirm that you are a UK taxpayer and that
          you would like to reclaim 25p of tax for every £1 of your donation
          made in the last four years and any future donations at no extra cost
          for you.
        </p>
        <Tooltip
          label="Important Information"
          text="Gift Aid is a tax relief allowing UK charities to reclaim an extra 25% in tax on every eligible donation made by a UK taxpayer. If you pay less income Tax and/or Capital Gains Tax in the current year than the amount of Gift Aid claimed on your donations in that tax year, it is your responsibility to pay the difference. We can’t claim Gift Aid on a donation made by you on behalf of a company or a friend. Higher rate taxpayers can claim tax relief on the donation. Gift Aid is not part of your Zakat."
        />
      </HeaderTextContainer>

      <OptionsContainer>
        <div>
          <StyledBoldText>
            Yes, claim {formatCurrency(getGiftAidPrice())} of Gift Aid
          </StyledBoldText>
        </div>
        <div>
          <button onClick={() => navigate("/app/payment")}>
            <StyledBoldText>No, do not claim Gift Aid</StyledBoldText>
          </button>
        </div>
      </OptionsContainer>
      <Formik
        validationSchema={ZakatGiftAidSchema}
        initialValues={{
          ...initialData,
          ...giftAid,
          ...{
            title: title,
          },
        }}
        onSubmit={handleNext}
      >
        {({ values, setFieldValue, errors, touched }) => {
          const handleChange = (
            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setFieldValue(e.target.name, e.target.value);
          };

          return (
            <StyledForm>
              <p>
                Please confirm a few details so we can claim your gift aid. We
                will only use your information for this purpose.
              </p>
              <div>
                <DropDown
                  value={title}
                  onChange={(val) => {
                    setTitle(val);
                    setFieldValue("title", val);
                  }}
                  heading="Title ⋆"
                  placeholder="Select your title (required for GiftAid)"
                  options={titleOptions}
                  errorMessage={
                    touched.title && errors.title ? errors.title : ""
                  }
                />
              </div>
              <Input
                value={values.firstName}
                name="firstName"
                onChange={handleChange}
                heading="First Name(s) ⋆"
                placeholder="Legal first name"
                errorMessage={
                  touched.firstName && errors.firstName ? errors.firstName : ""
                }
              />
              <Input
                value={values.lastName}
                name="lastName"
                onChange={handleChange}
                heading="Last Name ⋆"
                placeholder="Legal last name"
                errorMessage={
                  touched.lastName && errors.lastName ? errors.lastName : ""
                }
              />
              <Input
                value={values.addressLine1}
                name="addressLine1"
                onChange={handleChange}
                heading="Address Line 1 ⋆"
                placeholder="Enter here"
                errorMessage={
                  touched.addressLine1 && errors.addressLine1
                    ? errors.addressLine1
                    : ""
                }
              />
              <Input
                value={values.addressLine2 || ""}
                name="addressLine2"
                onChange={handleChange}
                heading="Address Line 2"
                placeholder="Enter here"
              />
              <Input
                value={values.city}
                name="city"
                onChange={handleChange}
                heading="City ⋆"
                placeholder="Enter your city"
                errorMessage={touched.city && errors.city ? errors.city : ""}
              />
              <Input
                value={values.postCode}
                name="postCode"
                onChange={handleChange}
                heading="Post Code ⋆"
                placeholder="Enter post code"
                errorMessage={
                  touched.postCode && errors.postCode ? errors.postCode : ""
                }
              />
              <ButtonContainer>
                <FormButton
                  type="submit"
                  text="Next"
                  leftArrowLabel="Back"
                  disclaimerIcon={<LockIcon />}
                  disclaimerText="We take your privacy seriously. Our institutional grade security ensures ensures your data is confidential."
                  leftArrowOnClick={() => navigate("/app/top-up")}
                />
              </ButtonContainer>
            </StyledForm>
          );
        }}
      </Formik>
    </PageWrapper>
  );
};

export default ZakatGiftAidPage;
