import { CommodityType } from "../../../components/PortfolioData/constants/commodity";
import {
  CommodityItemInterface,
  PortfolioDataInterface,
} from "@ifgengineering/hip-app-domain";

export const updatePortfolioValues = (
  portfolio: PortfolioDataInterface,
  goldPrice: number,
  silverPrice: number
): PortfolioDataInterface => {
  const { commodities } = portfolio;

  if (!commodities) {
    return portfolio;
  }

  let totalValue = 0;
  const commoditiesList: CommodityItemInterface[] = [];

  commodities.commoditiesList?.forEach((commodity) => {
    const { weight, commodityType, monetaryValue } = commodity;

    if (!weight) {
      totalValue += monetaryValue;
      commoditiesList.push(commodity);
      return;
    }

    switch (commodityType) {
      case CommodityType.GOLD:
        totalValue += weight * goldPrice;

        commoditiesList.push({
          ...commodity,
          monetaryValue: weight * goldPrice,
        });

        break;

      case CommodityType.SILVER:
        totalValue += weight * silverPrice;

        commoditiesList.push({
          ...commodity,
          monetaryValue: weight * silverPrice,
        });

        break;
      default:
        totalValue + monetaryValue;
        commoditiesList.push(commodity);
        break;
    }
  });

  return {
    ...portfolio,
    commodities: {
      ...commodities,
      totalValue,
      commoditiesList,
    },
  };
};
