import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { CORE_API } from "gatsby-env-variables";
import { toast } from "react-toastify";
import { logException } from "@helpers/logException";
import { STRIPE_PUBLISHABLE_KEY } from "gatsby-env-variables";
import { navigate } from "gatsby";

type ZakatPaymentRequestType = {
  userEmail: string;
  successUrl: string;
  cancelUrl: string;
};

export const ZakatPaymentCheckout = async (
  data: ZakatPaymentRequestType
): Promise<void> => {
  const stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY);

  try {
    const res = await axios.post(
      `${CORE_API}/zakat/payment`,
      {
        ...data,
      },
      {
        withCredentials: true,
      }
    );

    if (stripe && res.data.id) {
      await stripe.redirectToCheckout({
        sessionId: res.data.id,
      });
      return;
    }
  } catch (e: any) {
    if (
      e.response.data.error ||
      e.response.data.statusCode === 500 ||
      e.response.data.statusCode === 400
    ) {
      toast.error(e.response.data.message);

      navigate(`/app/charity-allocation`);

      logException({
        tag: "ZakatPaymentCheckout",
        exception: e,
      });

      throw Error(e);
    }
  }

  console.error("Error initiating Stripe");
};
