import styled from "styled-components";
import { theme } from "@willsTheme";
import { MEDIA_QUERIES, Text } from "@ifgengineering/component-library";

export const StyledBreakdownContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1em 4em;
  justify-content: space-between;
  & > div {
    width: max-content;
    text-align: center;
  }
  & > div > h2 {
    margin-top: 0.75em;
  }

  @media (min-width: 900px) {
    justify-content: space-around;
  }

  @media (max-width: 900px) {
    justify-content: space-around;
    gap: 1em 8em;
  }
  @media (max-width: 450px) {
    justify-content: center;
  }
`;

export const StyledTotalZakat = styled.div`
  width: max-content;
  text-align: center;
  background-color: #b2d5ac;
  border: solid darkgreen 2px;
  border-radius: 5px;
  padding: 0.5em;
  @media (min-width: 900px) {
    padding: 1em 2em;
  }
`;

export const TotalZakatSummaryContainer = styled.div<{
  small?: boolean;
  spaceBetween?: boolean;
  color?: string;
  borderColor?: string;
}>`
  display: flex;
  flex-direction: ${({ spaceBetween }) => (spaceBetween ? "row" : "column")};
  justify-content: ${({ spaceBetween }) =>
    spaceBetween ? "space-between" : "center"};
  padding: ${({ small }) => (small ? "1em 2em" : "2em 4em")};
  background-color: ${({ color }) => color || "#f3f9f6"};
  border-color: ${({ borderColor }) => borderColor || "#a2d0e4"};
  border-style: solid;
  border-width: 1.5px;
  border-radius: 5px;
  gap: ${({ small }) => (small ? "1em" : "2em")};
  color: ${theme.colors.BrandNavy};
  font-size: 16px;
  margin: 1.5rem;

  @media ${MEDIA_QUERIES.PHONE} {
    margin: 1.5rem 0;
  }
`;

export const StyledBoldText = styled(Text)`
  margin: 1em;
`;

export const StyledTitleText = styled(Text)`
  font-weight: 600;
`;
