import {
  StyledBoldText,
  StyledBreakdownContainer,
  StyledTitleText,
  StyledTotalZakat,
  TotalZakatSummaryContainer,
} from "./styled/styled";
import React from "react";
import { ZakatSummary as ZakatSummaryType } from "@Zakat/lib/zakat/zakatCalculator";

const ZakatSummary: React.FC<{
  formatCurrency: (x: number) => string;
  summary: ZakatSummaryType;
}> = ({ formatCurrency, summary }) => (
  <TotalZakatSummaryContainer>
    <StyledTitleText type="H16" color="BLUE900">
      Total Zakat due = (Zakatable assets - What I owe) x 0.025
    </StyledTitleText>
    <StyledBreakdownContainer>
      <div>
        <p>Zakatable assets:</p>
        <StyledBoldText type="H24" color="BLUE900">
          {formatCurrency(summary.zakatableAssets)}
        </StyledBoldText>
      </div>
      <div>
        <p>What I Owe (debt):</p>
        <StyledBoldText type="H24" color="BLUE900">
          {formatCurrency(summary.whatIOwe)}
        </StyledBoldText>
      </div>
      <div>
        <p>{"Today's Nisab:"}</p>
        <StyledBoldText type="H24" color="BLUE900">
          {formatCurrency(summary.nisab)}
        </StyledBoldText>
      </div>
      <StyledTotalZakat>
        <p>Total Zakat Due</p>
        <StyledTitleText type="H24" color="BLUE900">
          {summary.zakat < 0
            ? formatCurrency(0)
            : formatCurrency(summary.zakat)}
        </StyledTitleText>
      </StyledTotalZakat>
    </StyledBreakdownContainer>
  </TotalZakatSummaryContainer>
);

export default ZakatSummary;
