import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPortfolioAPI } from "@components/PortfolioData/actions/portfolio";
import { updatePortfolio } from "@components/PortfolioData/actions";
import store from "../../store";
import {
  getUserEmail,
  neverLoadedAuthSelector,
} from "@components/Login/selectors";
import {
  fetchUserInfoAPI,
  logoutAction,
  updateUserInfo,
} from "../../../components/Login/actions";
import { findOrCreateZakatAPI } from "@Zakat/store/actions/zakat";
import { CurrencyContext } from "@components/CurrencyContext";
import { Currency } from "@ifgengineering/hip-app-domain";
import { fetchSilverPriceAPI } from "@Zakat/store/actions/silver";
import { getPortfolioCurrency } from "@components/PortfolioData/selectors";
import { fetchWillAPI } from "@components/Wills/actions/will";
import { updateWills } from "@components/Wills/actions";
import {
  fetchCharityByIdAPI,
  updateCharityStore,
} from "@components/Charity/actions";
import { logException } from "@helpers/logException";

const PreFetchData: React.FC = ({ children }) => {
  const userEmail = useSelector(getUserEmail);
  const dispatch = useDispatch<typeof store.dispatch>();
  const { setCurrency, setSilverPrice } = useContext(CurrencyContext);
  const portfolioCurrency = useSelector(getPortfolioCurrency);
  const neverLoadedAuth = useSelector(neverLoadedAuthSelector);

  async function portfolioAndZakat() {
    const { data, errors } = await dispatch(
      fetchPortfolioAPI(userEmail)
    ).unwrap();

    if (data) {
      await dispatch(
        findOrCreateZakatAPI({
          data: {
            currency: data?.currency,
          },
          email: userEmail,
        })
      );
      dispatch(updatePortfolio(data));
      setCurrency(data.currency as Currency);
    }

    if (errors?.some(({ statusCode }) => statusCode === 401)) {
      dispatch(logoutAction());
    }
  }

  async function userInfo() {
    const { data, errors } = await dispatch(fetchUserInfoAPI()).unwrap();

    if (data) {
      dispatch(updateUserInfo(data));
    }

    if (errors?.some(({ statusCode }) => statusCode === 401)) {
      dispatch(logoutAction());
    }
  }
  async function willData() {
    const { data } = await dispatch(
      fetchWillAPI({ email: userEmail })
    ).unwrap();

    if (!data) return;

    if (data?.partnerCharityId) {
      const charity = await dispatch(
        fetchCharityByIdAPI(data.partnerCharityId)
      ).unwrap();

      dispatch(updateCharityStore(charity));
    }
    dispatch(updateWills(data));

    return data;
  }

  async function fetchSilverPriceForCurrentCurrency() {
    try {
      const data = await dispatch(
        fetchSilverPriceAPI({ currency: portfolioCurrency as Currency })
      ).unwrap();
      setSilverPrice(data.value);
    } catch (error) {
      logException({
        tag: "fetchSilverPriceForCurrentCurrency",
        exception: error,
      });
    }
  }

  useEffect(() => {
    if (portfolioCurrency) {
      fetchSilverPriceForCurrentCurrency();
    }
  }, [portfolioCurrency]);

  useEffect(() => {
    if (neverLoadedAuth) {
      userInfo();
    }
  }, [neverLoadedAuth]);

  useEffect(() => {
    if (userEmail) {
      portfolioAndZakat();
      willData();
    }
  }, [userEmail]);

  return <>{children}</>;
};

export default PreFetchData;
