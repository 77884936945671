import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import store from "../../store";
import { zakatTabs } from "./constants/constants";
import { NavigationBar } from "@ifgengineering/component-library";
import { navigate as outerNav } from "@reach/router";
import { navigate as innerNav } from "gatsby";
import { ChildrenLayout } from "./styled";
import { logoutAction } from "../../../components/Login/actions";
import {
  NavBar,
  NavMenuItemsPosition,
  MenuTemplateItem,
} from "@ifgengineering/client-app-navbar";
import { WEALTH_SITE_URL, CUR8_SITE_URL } from "gatsby-env-variables";
import useAnalytics from "@helpers/useAnalytics";
import styled from "styled-components";

const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`;

export const NavigationLayout: React.FC = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch<typeof store.dispatch>();
  const fireAppSwitchAnalytics = useAnalytics("appSwitch");
  const firePageViewAnalytics = useAnalytics("pageView");
  const firePageView = async (eventName: string) => {
    (await firePageViewAnalytics)({ eventName });
  };

  useEffect(() => {
    firePageView("zakat:zakatCalculator_viewed");
  }, []);

  const template: MenuTemplateItem[] = [
    {
      tab: "cur8",
      label: "Invest",
      selected: false,
      position: NavMenuItemsPosition.MAIN,
      onClick: async () => {
        (await fireAppSwitchAnalytics)("Cur8");
        await outerNav(`${CUR8_SITE_URL}/app/deals`);
      },
    },
    {
      tab: "help",
      label: "Help",
      selected: false,
      position: NavMenuItemsPosition.SECONDARY,
      onClick: () => outerNav(`${WEALTH_SITE_URL}/app/compare?view=help`),
    },
  ];
  const currentPage = location.pathname.split("/")[2];
  const onSetPage = (page: string) => {
    innerNav(`/app/${page}`);
  };

  return (
    <NavBar
      hideTopBarMobile
      hideTopBarDesktop
      template={template}
      isOpen={isMenuOpen}
      toggleMenu={() => setIsMenuOpen(!isMenuOpen)}
      page="Zakat"
      logoutCallback={() => dispatch(logoutAction())}
    >
      <NavigationWrapper>
        <NavigationBar
          navItemsTextColor="WHITE"
          selectedNavItemsTextColor="WHITE"
          bgColor="BLACK"
          selectedNavItemsBgColor="BLUE600"
          selectedItemId={currentPage}
          pageName="Zakat"
          navItems={zakatTabs}
          onSelectNavItem={(item) => onSetPage(item.id)}
        />

        <ChildrenLayout>{children}</ChildrenLayout>
      </NavigationWrapper>
    </NavBar>
  );
};

export default NavigationLayout;
