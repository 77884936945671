import styled from "styled-components";
import { theme } from "@Wills/styles/theme";
import { Form } from "formik";

export const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 3em;
  & > p {
    font-weight: 400;
    font-size: 16px;
    color: ${theme.colors.Font02};
  }
`;

export const StyledForm = styled(Form)`
  margin: 2em 0;
  & > p {
    color: ${theme.colors.Font02};
    font-weight: 400;
    font-size: 16px;
    padding: 1em 0;
  }
  & > div:nth-child(2) {
    position: relative;
    width: 70%;
    @media (max-width: 425px) {
      width: 100%;
    }
    & > div > p {
      font-weight: 500;
      font-size: small;
      margin-bottom: 0.5em;
    }
  }
  & > div > p {
    font-weight: 500;
    font-size: small;
    margin-bottom: 0.5em;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 2em;
`;

export const ZakatGiftAidContainer = styled.div`
  padding: 5vh 10vw;
`;

export const ZakatGiftAidContent = styled.div`
  max-width: 560px;
  margin: 0 auto;
`;
