import styled from "styled-components";

export const ChildrenLayout = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 600px) {
    margin-bottom: 60px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  & > div {
    display: flex;
    gap: 1rem;
    white-space: nowrap;
  }
`;
