import { createAsyncThunk } from "@reduxjs/toolkit";
import { gql } from "@apollo/client";
import { toast } from "react-toastify";
import { client } from "../../../graphql";
import { CREATE_GIFT_AID, UPDATE_GIFT_AID } from "../constants/actionTypes";
import { ZakatGiftAid } from "../reducers/reducers";
import { logException } from "@helpers/logException";

export const createGiftAidAPI = createAsyncThunk<
  ZakatGiftAid,
  {
    data: ZakatGiftAid;
    email: string;
  }
>(CREATE_GIFT_AID, async ({ data, email }) => {
  try {
    const response = await client.mutate({
      fetchPolicy: "no-cache",
      mutation: gql`
        mutation ($data: GiftAidInput!, $email: String!) {
          createGiftAid(data: $data, userEmail: $email) {
            id
            title
            firstName
            lastName
            addressLine1
            addressLine2
            city
            postCode
          }
        }
      `,
      variables: {
        data,
        email,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return;
    }

    return response.data.createGiftAid;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      email,
      tag: "createGiftAidAPI",
      exception: e,
    });

    throw Error(e);
  }
});

export const updateGiftAidAPI = createAsyncThunk<
  ZakatGiftAid,
  {
    data: ZakatGiftAid;
    email: string;
  }
>(UPDATE_GIFT_AID, async ({ data, email }) => {
  try {
    const response = await client.mutate({
      fetchPolicy: "no-cache",
      mutation: gql`
        mutation ($data: PartialGiftAidInput!, $email: String!) {
          updateGiftAid(data: $data, userEmail: $email) {
            id
            title
            firstName
            lastName
            addressLine1
            addressLine2
            city
            postCode
          }
        }
      `,
      variables: {
        data,
        email,
      },
    });

    if (response.errors) {
      toast.warn("Something went wrong. Try again or contact support");
      return;
    }

    return response.data.updateGiftAid;
  } catch (e: any) {
    toast.warn("Something went wrong. Try again or contact support");

    logException({
      email,
      tag: "updateGiftAidAPI",
      exception: e,
    });

    throw Error(e);
  }
});
