import React, { useEffect } from "react";
import FormButton from "@legacyComponents/FormButton/FormButton.component";
import { Description, InnerWrapper, Layout, Text } from "./styled";
import { navigate } from "gatsby";
import useAnalytics from "@helpers/useAnalytics";

const Dua: React.FC = () => {
  const firePageViewAnalytics = useAnalytics("pageView");

  useEffect(() => {
    const firePageView = async (eventName: string) => {
      (await firePageViewAnalytics)({ eventName });
    };
    firePageView("zakat:dua_pageViewed");
  }, []);

  return (
    <Layout>
      <InnerWrapper>
        <Text>Take a moment to just collect your thoughts and pray</Text>
        <Description>
          This is the third pillar of your faith. One of the 5 things that make
          you fulfill your Islam. Make sincere dua now.
        </Description>
      </InnerWrapper>
      <InnerWrapper>
        <FormButton
          text="Ameen, Proceed to Payment"
          onClick={() => {
            window.open("/app/redirect-checkout", "_blank")?.focus();
          }}
          leftArrowLabel="Back"
          leftArrowOnClick={() => navigate("/app/payment")}
        />
      </InnerWrapper>
    </Layout>
  );
};

export default Dua;
